import React from 'react'

const Business = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.66634 3.33366V1.33366C3.66634 1.15685 3.73658 0.987279 3.8616 0.862254C3.98663 0.73723 4.1562 0.666992 4.33301 0.666992H9.66634C9.84315 0.666992 10.0127 0.73723 10.1377 0.862254C10.2628 0.987279 10.333 1.15685 10.333 1.33366V3.33366H12.9997C13.1765 3.33366 13.3461 3.4039 13.4711 3.52892C13.5961 3.65395 13.6663 3.82351 13.6663 4.00033V13.3337C13.6663 13.5105 13.5961 13.68 13.4711 13.8051C13.3461 13.9301 13.1765 14.0003 12.9997 14.0003H0.999674C0.822863 14.0003 0.653294 13.9301 0.52827 13.8051C0.403246 13.68 0.333008 13.5105 0.333008 13.3337V4.00033C0.333008 3.82351 0.403246 3.65395 0.52827 3.52892C0.653294 3.4039 0.822863 3.33366 0.999674 3.33366H3.66634ZM4.99967 8.66699H1.66634V12.667H12.333V8.66699H8.99967V10.667H4.99967V8.66699ZM12.333 4.66699H1.66634V7.33366H4.99967V6.00033H8.99967V7.33366H12.333V4.66699ZM6.33301 7.33366V9.33366H7.66634V7.33366H6.33301ZM4.99967 2.00033V3.33366H8.99967V2.00033H4.99967Z" fill="#2D3748" />
        </svg>
    )
}

export default Business