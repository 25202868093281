import React from 'react'

const BeemzLocationIcon = ({ size, color }) => {
    return (
        <svg width={size ? size : '16'} height={size ? size : '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00001 6.66659C5.8232 6.66659 5.65363 6.73683 5.52861 6.86185C5.40359 6.98688 5.33335 7.15644 5.33335 7.33326V9.99992H6.66668V7.99992H8.66668V9.66659L11 7.33326L8.66668 4.99992V6.66659H6.00001ZM8.47135 0.928589L15.0713 7.52859C15.1963 7.65361 15.2665 7.82315 15.2665 7.99992C15.2665 8.1767 15.1963 8.34624 15.0713 8.47126L8.47135 15.0713C8.34633 15.1962 8.17679 15.2664 8.00001 15.2664C7.82324 15.2664 7.6537 15.1962 7.52868 15.0713L0.928681 8.47126C0.8037 8.34624 0.73349 8.1767 0.73349 7.99992C0.73349 7.82315 0.8037 7.65361 0.928681 7.52859L7.52868 0.928589C7.6537 0.803609 7.82324 0.733398 8.00001 0.733398C8.17679 0.733398 8.34633 0.803609 8.47135 0.928589Z" fill={color ? color : "#2D3748"} />
        </svg>

    )
}

export default BeemzLocationIcon