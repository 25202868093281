import React from 'react'

const MapEditIcon = ({ w, h }) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.162 10.6667H12V12.0001H0V9.17139L6.6 2.57139L9.428 5.40072L4.16133 10.6667H4.162ZM7.542 1.62939L8.95667 0.214722C9.08169 0.0897416 9.25122 0.0195312 9.428 0.0195312C9.60478 0.0195312 9.77432 0.0897416 9.89933 0.214722L11.7853 2.10072C11.9103 2.22574 11.9805 2.39528 11.9805 2.57206C11.9805 2.74883 11.9103 2.91837 11.7853 3.04339L10.3707 4.45739L7.54267 1.62939H7.542Z" fill="#2D3748"/>
        </svg>
    )
}

export default MapEditIcon