import React from 'react'

const Event = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.99992 0.666992V2.00033H8.99992V0.666992H10.3333V2.00033H12.9999C13.1767 2.00033 13.3463 2.07056 13.4713 2.19559C13.5963 2.32061 13.6666 2.49018 13.6666 2.66699V13.3337C13.6666 13.5105 13.5963 13.68 13.4713 13.8051C13.3463 13.9301 13.1767 14.0003 12.9999 14.0003H0.999919C0.823108 14.0003 0.653538 13.9301 0.528514 13.8051C0.40349 13.68 0.333252 13.5105 0.333252 13.3337V2.66699C0.333252 2.49018 0.40349 2.32061 0.528514 2.19559C0.653538 2.07056 0.823108 2.00033 0.999919 2.00033H3.66659V0.666992H4.99992ZM12.3333 5.33366H1.66659V12.667H12.3333V5.33366ZM9.02392 6.75766L9.96659 7.70033L6.66659 11.0003L4.30925 8.64299L5.25325 7.70033L6.66725 9.11499L9.02459 6.75766H9.02392Z" fill="#2D3748" />
        </svg>
    )
}

export default Event