import React from 'react'

const SearchIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0207 10.0782L13.876 12.9328L12.9327 13.8762L10.078 11.0208C9.01587 11.8723 7.69471 12.3354 6.33337 12.3335C3.02137 12.3335 0.333374 9.6455 0.333374 6.3335C0.333374 3.0215 3.02137 0.333496 6.33337 0.333496C9.64537 0.333496 12.3334 3.0215 12.3334 6.3335C12.3353 7.69483 11.8722 9.01599 11.0207 10.0782ZM9.68337 9.5835C10.5294 8.71342 11.002 7.54712 11 6.3335C11 3.75483 8.91137 1.66683 6.33337 1.66683C3.75471 1.66683 1.66671 3.75483 1.66671 6.3335C1.66671 8.9115 3.75471 11.0002 6.33337 11.0002C7.54699 11.0021 8.7133 10.5296 9.58337 9.6835L9.68337 9.5835Z" fill="#97A8BE" />
        </svg>

    )
}

export default SearchIcon