import React from 'react'

const ArrowDownCircle = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="11.25" stroke="white" strokeWidth="1.5" />
            <path d="M6.54547 9.81812L12 15.2727L17.4546 9.81812H6.54547Z" fill="white" />
        </svg>

    )
}

export default ArrowDownCircle