import React from 'react'

const DoubleLocationMarker = () => {
    return (
        <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.39 9.39013L5.5 13.2771L1.61 9.39013C0.841072 8.62084 0.317504 7.64083 0.105495 6.574C-0.106515 5.50718 0.00255424 4.40144 0.418912 3.3966C0.83527 2.39175 1.54022 1.53292 2.44463 0.928691C3.34905 0.324457 4.41232 0.00195313 5.5 0.00195312C6.58769 0.00195312 7.65096 0.324457 8.55537 0.928691C9.45978 1.53292 10.1647 2.39175 10.5811 3.3966C10.9975 4.40144 11.1065 5.50718 10.8945 6.574C10.6825 7.64083 10.1589 8.62084 9.39 9.39013ZM5.5 7.50013C6.03044 7.50013 6.53914 7.28941 6.91422 6.91434C7.28929 6.53927 7.5 6.03056 7.5 5.50013C7.5 4.96969 7.28929 4.46099 6.91422 4.08591C6.53914 3.71084 6.03044 3.50013 5.5 3.50013C4.96957 3.50013 4.46086 3.71084 4.08579 4.08591C3.71072 4.46099 3.5 4.96969 3.5 5.50013C3.5 6.03056 3.71072 6.53927 4.08579 6.91434C4.46086 7.28941 4.96957 7.50013 5.5 7.50013ZM18.39 18.3901L14.5 22.2781L10.61 18.3891C9.84107 17.6198 9.3175 16.6398 9.1055 15.573C8.89349 14.5062 9.00255 13.4004 9.41891 12.3956C9.83527 11.3908 10.5402 10.5319 11.4446 9.92769C12.349 9.32346 13.4123 9.00095 14.5 9.00095C15.5877 9.00095 16.651 9.32346 17.5554 9.92769C18.4598 10.5319 19.1647 11.3908 19.5811 12.3956C19.9974 13.4004 20.1065 14.5062 19.8945 15.573C19.6825 16.6398 19.1589 17.6198 18.39 18.3891V18.3901ZM14.5 16.5001C15.0304 16.5001 15.5391 16.2894 15.9142 15.9143C16.2893 15.5393 16.5 15.0306 16.5 14.5001C16.5 13.9697 16.2893 13.461 15.9142 13.0859C15.5391 12.7108 15.0304 12.5001 14.5 12.5001C13.9696 12.5001 13.4609 12.7108 13.0858 13.0859C12.7107 13.461 12.5 13.9697 12.5 14.5001C12.5 15.0306 12.7107 15.5393 13.0858 15.9143C13.4609 16.2894 13.9696 16.5001 14.5 16.5001Z" fill="#6200EE" />
        </svg>

    )
}

export default DoubleLocationMarker