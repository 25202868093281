import React from 'react'

const PostIcon = () => {
    return (
        
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 1.5H2.5C2.10218 1.5 1.72064 1.65804 1.43934 1.93934C1.15804 2.22064 1 2.60218 1 3V14C1 14.3978 1.15804 14.7794 1.43934 15.0607C1.72064 15.342 2.10218 15.5 2.5 15.5H13.5C13.8978 15.5 14.2794 15.342 14.5607 15.0607C14.842 14.7794 15 14.3978 15 14V3C15 2.60218 14.842 2.22064 14.5607 1.93934C14.2794 1.65804 13.8978 1.5 13.5 1.5ZM5.5 14.5H2.5C2.36739 14.5 2.24021 14.4473 2.14645 14.3536C2.05268 14.2598 2 14.1326 2 14V11.5H5.5V14.5ZM5.5 10.5H2V7H5.5V10.5ZM5.5 6H2V3C2 2.86739 2.05268 2.74021 2.14645 2.64645C2.24021 2.55268 2.36739 2.5 2.5 2.5H5.5V6ZM10 14.5H6.5V11.5H10V14.5ZM10 10.5H6.5V7H10V10.5ZM10 6H6.5V2.5H10V6ZM14 14C14 14.1326 13.9473 14.2598 13.8536 14.3536C13.7598 14.4473 13.6326 14.5 13.5 14.5H11V11.5H14V14ZM14 10.5H11V7H14V10.5ZM14 6H11V2.5H13.5C13.6326 2.5 13.7598 2.55268 13.8536 2.64645C13.9473 2.74021 14 2.86739 14 3V6Z" fill="#2D3748" />
        </svg>
    )
}

export default PostIcon