import React from 'react'

const LocationPinCircleBase = ({ w, h }) => {
    return (
        <svg width={w ? w : '12'} height={h ? h : '12'} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99981 0C4.03769 0 2.42969 1.6032 2.42969 3.55992C2.42969 4.31808 2.67185 5.02272 3.08165 5.60184L5.56409 9.8934C5.91173 10.3476 6.14285 10.2613 6.43193 9.8694L9.17009 5.2098C9.22529 5.10972 9.26873 5.00316 9.30653 4.89432C9.48028 4.4709 9.56963 4.01761 9.56957 3.55992C9.56969 1.6032 7.96217 0 5.99981 0ZM5.99981 1.668C7.05653 1.668 7.89677 2.50632 7.89677 3.55992C7.89677 4.61352 7.05653 5.45148 5.99981 5.45148C4.94321 5.45148 4.10261 4.61364 4.10261 3.55992C4.10261 2.50632 4.94321 1.66812 5.99981 1.66812V1.668Z" fill="#696D7A" />
            <path d="M8.27269 5.86914L8.26692 5.88426C8.26872 5.8797 8.27016 5.87502 8.27197 5.87046L8.27269 5.86914ZM4.08385 8.28702C2.38873 8.52654 1.20312 9.09954 1.20312 9.94302C1.20312 11.0792 3.18409 12.0002 6.00313 12.0002C8.82217 12.0002 10.8031 11.0792 10.8031 9.94302C10.8031 9.09954 9.61765 8.52654 7.92265 8.28702L7.68781 8.68686C8.96533 8.8641 9.84313 9.23658 9.84313 9.66882C9.84313 10.2748 8.12389 10.766 6.00313 10.766C3.88236 10.766 2.16313 10.2748 2.16313 9.66882C2.16301 9.23802 3.03505 8.8659 4.31652 8.68794C4.23913 8.55426 4.16125 8.42082 4.08373 8.28702H4.08385Z" fill="#696D7A" />
        </svg>
    )
}

export default LocationPinCircleBase