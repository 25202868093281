import React from 'react'

const UserPlusColoredIcon = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5161 13.0914H3.48387C3.19884 13.0914 2.96774 12.8603 2.96774 12.5752V11.0472C2.96774 10.3192 3.39723 9.65708 4.06194 9.36031C5.99516 8.49708 5.88919 8.52628 6.03984 8.52628H6.65329C6.89084 8.52628 7.09771 8.68841 7.15448 8.91908C7.37084 9.79831 8.6291 9.79873 8.84555 8.91908C8.90232 8.68844 9.10919 8.52628 9.34674 8.52628C10.0069 8.52628 10.0408 8.51318 10.1706 8.57112L11.9381 9.36031C12.6028 9.65708 13.0323 10.3192 13.0323 11.0472V12.5752C13.0323 12.8603 12.8012 13.0914 12.5161 13.0914ZM8.01261 0.90918C6.27423 0.90918 4.85997 2.32344 4.85997 4.06183C4.85997 5.80021 6.27423 7.21447 8.01261 7.21447C9.751 7.21447 11.1653 5.80021 11.1653 4.06183C11.1653 2.32344 9.751 0.90918 8.01261 0.90918ZM3.03929 4.28131C1.94474 4.28131 1.05426 5.17179 1.05426 6.26637C1.05426 7.36092 1.94474 8.25141 3.03929 8.25141C4.13387 8.25141 5.02435 7.36092 5.02435 6.26637C5.02432 5.17179 4.13384 4.28131 3.03929 4.28131ZM12.9607 4.28131C11.8661 4.28131 10.9756 5.17179 10.9756 6.26637C10.9756 7.36092 11.8661 8.25141 12.9607 8.25141C14.0553 8.25141 14.9457 7.36092 14.9457 6.26637C14.9457 5.17179 14.0553 4.28131 12.9607 4.28131ZM15.2548 9.00963C14.1809 8.53002 14.2066 8.52511 14.0597 8.52511C13.2909 8.52511 12.7879 8.51973 12.4477 8.51805C12.1096 8.51637 11.9976 8.96989 12.2972 9.1266C12.5425 9.25492 12.6915 9.36437 12.8684 9.55741C13.3846 10.1181 13.4327 10.7115 13.4326 11.2034C13.4326 11.3816 13.5769 11.5258 13.7551 11.5258H15.4839C15.769 11.5258 16 11.2945 16 11.0097V10.1583C16 9.66253 15.7074 9.21189 15.2548 9.00963ZM1.94032 8.52511C1.79406 8.52511 1.82284 8.52834 0.745161 9.00963C0.292581 9.21189 0 9.66253 0 10.1583V11.0096C0 11.2945 0.230968 11.5258 0.516129 11.5258H2.24477C2.42303 11.5258 2.56748 11.3814 2.56726 11.2031C2.56668 10.7094 2.61335 10.1203 3.13161 9.55737C3.30765 9.36528 3.45784 9.25486 3.69774 9.12882C3.99674 8.97176 3.88626 8.51957 3.54852 8.52015C3.20629 8.52073 2.70342 8.52511 1.94032 8.52511Z" fill="#2D3748"/>
        </svg>
    )
}

export default UserPlusColoredIcon