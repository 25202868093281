import React from 'react'

const UserCircle = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.333008 14.667C0.333008 13.2525 0.894911 11.896 1.89511 10.8958C2.8953 9.89556 4.25185 9.33366 5.66634 9.33366C7.08083 9.33366 8.43738 9.89556 9.43758 10.8958C10.4378 11.896 10.9997 13.2525 10.9997 14.667H9.66634C9.66634 13.6061 9.24491 12.5887 8.49477 11.8386C7.74462 11.0884 6.72721 10.667 5.66634 10.667C4.60548 10.667 3.58806 11.0884 2.83791 11.8386C2.08777 12.5887 1.66634 13.6061 1.66634 14.667H0.333008ZM5.66634 8.66699C3.45634 8.66699 1.66634 6.87699 1.66634 4.66699C1.66634 2.45699 3.45634 0.666992 5.66634 0.666992C7.87634 0.666992 9.66634 2.45699 9.66634 4.66699C9.66634 6.87699 7.87634 8.66699 5.66634 8.66699ZM5.66634 7.33366C7.13967 7.33366 8.33301 6.14033 8.33301 4.66699C8.33301 3.19366 7.13967 2.00033 5.66634 2.00033C4.19301 2.00033 2.99967 3.19366 2.99967 4.66699C2.99967 6.14033 4.19301 7.33366 5.66634 7.33366ZM11.189 9.80233C12.1259 10.2243 12.921 10.9078 13.4787 11.7708C14.0365 12.6338 14.3331 13.6395 14.333 14.667H12.9997C12.9998 13.8963 12.7774 13.142 12.3591 12.4947C11.9408 11.8474 11.3444 11.3348 10.6417 11.0183L11.1883 9.80233H11.189ZM10.7303 2.27566C11.402 2.55252 11.9763 3.02268 12.3804 3.62648C12.7844 4.23027 12.9999 4.94049 12.9997 5.66699C13 6.58188 12.6581 7.46381 12.0414 8.13954C11.4246 8.81528 10.5775 9.23597 9.66634 9.31899V7.97699C10.1603 7.90625 10.6186 7.67901 10.9739 7.32863C11.3292 6.97826 11.5628 6.52321 11.6404 6.03028C11.7181 5.53736 11.6357 5.03252 11.4053 4.58989C11.1749 4.14727 10.8086 3.79016 10.3603 3.57099L10.7303 2.27566Z" fill="#6200ee" />
        </svg>


    )
}

export default UserCircle