import React, { useEffect, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import { withTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchBox from "../common/SearchBox";
import _ from "lodash";

const MultipleLocationPopup = (props) => {
  const {
    perviousData,
    locationLimit,
    addressData,
    latData,
    longData,
    popupFiled,
  } = props
  const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_LEY;
  const [libraries] = useState(["places"]);
  const [lattitudeData, setLattitudeData] = useState();
  const [longitudeData, setLongitudeData] = useState();
  const [addressMapData, setAddressMapData] = useState([]);
  const [addressMapStoreData, setAddressMapStoreData] = useState({});
  const [lattitude, setLattitude] = useState();
  const [longitude, setLongitude] = useState();
  const [autoComplete, setAutoComplete] = useState();
  const [location, setLocation] = useState({});
  const [addressInput,setAddressInput] = useState("")
  useEffect(() => {
    if(!_.isEmpty(perviousData)){
      setAddressMapData(perviousData)
    }
  },[perviousData])
  // const geoLocation = () => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     setLattitude(position.coords.latitude);
  //     setLongitude(position.coords.longitude);
  //     setLattitudeData(position.coords.latitude);
  //     setLongitudeData(position.coords.longitude);
  //   });
  // };
  // useEffect(() => {
  //   geoLocation();
  // }, []);
  const popupClose = () => {
    if(!_.isEmpty(addressMapStoreData)){
      setAddressMapData([addressMapStoreData, ...addressMapData]);
    }
    setAddressMapStoreData({})
    setAddressInput("")
    setLattitudeData("")
    setLongitudeData("")
    setLattitude("")
    setLongitude("")
    document.getElementById("multipleLocationPopup").classList.remove("show");
    document.body.removeAttribute("style");
  };

  const center = {
    lat: lattitudeData ? Number(lattitudeData) : lattitude ? lattitude : -26.204444,
    lng: longitudeData ? Number(longitudeData) : longitude ? longitude : 28.045556,
  };

  const mapViewCenter = {
    lat: latData ? Number(latData) : -26.204444,
    lng: longData ? Number(longData) : 28.045556,
  }

  const handleClickMap = (event) => {
    var lat = event.latLng.lat().toString();
    var lng = event.latLng.lng().toString();
    setLongitudeData(lng);
    setLattitudeData(lat);
    Geocode.setApiKey(googleMapKey);

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddressInput(address);
        const addressObj = {
          physicalAddress: address,
          location: {
            type: "Point",
            coordinates: [lng, lat]
          }
        }
        setAddressMapStoreData(addressObj);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const hadnleLocationPopup = () => {
    const locationPopup = document.getElementById("multipleLocationPopup");
    locationPopup.classList.add("show");
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = "0px";
  };

  const onAutoCompletePlaceIsChanged = () => {
    if (autoComplete !== null) {
      var place = autoComplete.getPlace();
      location.lat = place.geometry.location.lat();
      location.lng = place.geometry.location.lng();
      location.address = place.vicinity;
      const addressObj = {
        physicalAddress: place.formatted_address,
        location: {
          type: "Point",
          coordinates: [location.lng, location.lat]
        }
      }
      setAddressInput(place.formatted_address)
      setAddressMapStoreData(addressObj);
      setLongitudeData(location.lng);
      setLattitudeData(location.lat);
    }
  };

  const onAutoCompleteIsLoad = (inputAutoComplete) => {
    setAutoComplete(inputAutoComplete);
  };

  const handleChange = (event) => {
    setAddressInput(event.target.value);
  };
  const handleAddressRemove = (address) => {
    setAddressMapData(addressMapData.filter((add,index) => {
      return index !== address 
    }))
  }

  useEffect(() => {
    addressData(addressMapData);
  },[addressMapData]);

  return (
    <>
      <LoadScript googleMapsApiKey={googleMapKey} libraries={libraries}>
        <div className={`${!popupFiled ? "col" : ""}`} >
          {/* <Autocomplete
            onLoad={onAutoCompleteIsLoad}
            onPlaceChanged={onAutoCompletePlaceIsChanged}
          > */}
          {addressMapData &&
            addressMapData.map((address,i) => {
              return <Box key={i} className="" sx={{ width: "100%", padding: "16.5px 14px", border: "1px solid #0000003b", borderRadius: "5px", marginBottom: "24px", position: "relative" }}>
                <Typography component={"p"} className="app_text_14_500 app_text_transform" >
                  {address?.physicalAddress}
                </Typography>
                <span style={{ width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "#6200EE", display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", top: "50%", right: "8px", transform: "translateY(-50%)", cursor: "pointer" }} onClick={() => { handleAddressRemove(i) }}>
                  <CloseRoundedIcon sx={{ width: "16px", height: "16px", color: '#fff' }} />
                </span>
              </Box>
            })
          }
          {/* </Autocomplete> */}
        </div>
          <div className={`${!popupFiled ? "col" : ""}`}>
            <Button
              onClick={() => hadnleLocationPopup()}
              fullWidth
              className="app_text_transform app_btn_lg"
              variant="outlined"
              disabled={locationLimit && addressMapData?.length < locationLimit ? false : true}
            >
              select address
            </Button>
          </div>
        <section id="multipleLocationPopup" className="location_popup_overlay">
          <div className="container position-relative">
            <Button
              onClick={() => popupClose()}
              className="app_blank_btn popup_close_btn"
              variant="outlined"
            >
              <CloseOutlinedIcon />
            </Button>
            <div className="location_popup_searchbox_container" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
              <Autocomplete
                onLoad={onAutoCompleteIsLoad}
                onPlaceChanged={onAutoCompletePlaceIsChanged}
              >
                <SearchBox
                  placeholder={props.t("comman_label.search_address")}
                  onChange={handleChange}
                  value={addressInput}
                />
              </Autocomplete>
            </div>
            <div className="location_box">
              <div className="location_map">
                <GoogleMap
                  mapContainerClassName="w-100 h-100 position-relative"
                  options={{
                    streetViewControl: false,
                    scaleControl: true,
                    mapTypeControl: false,
                    panControl: false,
                    zoomControl: true,
                    rotateControl: false
                  }}
                  keyboardShortcuts={false}
                  draggable={true}
                  zoom={13}
                  center={center}
                  onClick={(e) => handleClickMap(e)}
                >
                  <Marker
                    key={"Location 1"}
                    position={{
                      lat: Number(lattitudeData),
                      lng: Number(longitudeData),
                    }}
                  />
                </GoogleMap>
              </div>
            </div>
          </div>
        </section>

      </LoadScript>
    </>
  );
};

export default withTranslation()(MultipleLocationPopup);
