import React from 'react'

const Roles = () => {
    return (
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0.666992L11.478 1.88433C11.626 1.91723 11.7584 1.99964 11.8533 2.11793C11.9482 2.23622 12 2.38334 12 2.53499V9.19299C12 9.85148 11.8373 10.4998 11.5266 11.0803C11.2158 11.6609 10.7666 12.1557 10.2187 12.521L6 15.3337L1.78133 12.521C1.23352 12.1558 0.784307 11.6611 0.473565 11.0806C0.162824 10.5002 0.000157571 9.85204 0 9.19366V2.53499C2.60252e-05 2.38334 0.0517586 2.23622 0.14666 2.11793C0.241561 1.99964 0.373958 1.91723 0.522 1.88433L6 0.666992ZM6 2.03299L1.33333 3.06966V9.19299C1.33334 9.63196 1.44172 10.0641 1.64884 10.4512C1.85597 10.8382 2.15543 11.1681 2.52067 11.4117L6 13.7317L9.47933 11.4117C9.84447 11.1682 10.1439 10.8384 10.351 10.4515C10.5581 10.0646 10.6665 9.63252 10.6667 9.19366V3.06966L6 2.03366V2.03299ZM6 4.66699C6.29356 4.66686 6.57896 4.76361 6.81192 4.94223C7.04487 5.12086 7.21237 5.37137 7.28842 5.65491C7.36447 5.93844 7.34482 6.23915 7.23252 6.51038C7.12023 6.78161 6.92156 7.0082 6.66733 7.15499L6.66667 10.0003H5.33333V7.15499C5.07916 7.00823 4.88052 6.7817 4.76821 6.51054C4.65589 6.23937 4.63619 5.93873 4.71216 5.65523C4.78812 5.37173 4.95551 5.12121 5.18836 4.94254C5.4212 4.76386 5.7065 4.66701 6 4.66699Z" fill="#2D3748" />
        </svg>
    )
}

export default Roles