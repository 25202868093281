import React from 'react'

const Trust = () => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00027 0.333008L10.8176 4.45501L15.6089 5.86101L12.5589 9.81434L12.7023 14.805L8.00027 13.1263L3.29827 14.805L3.4416 9.81434L0.391602 5.86101L5.18293 4.45501L8.00027 0.333008ZM8.00027 2.69567L6.01494 5.60101L2.63827 6.59101L4.78827 9.37634L4.68627 12.893L8.00027 11.7103L11.3136 12.893L11.2123 9.37634L13.3616 6.59101L9.9856 5.60101L8.00027 2.69567ZM6.66693 7.99967C6.66693 8.3533 6.80741 8.69244 7.05746 8.94248C7.30751 9.19253 7.64665 9.33301 8.00027 9.33301C8.35389 9.33301 8.69303 9.19253 8.94308 8.94248C9.19312 8.69244 9.3336 8.3533 9.3336 7.99967H10.6669C10.6669 8.70692 10.386 9.3852 9.88589 9.88529C9.38579 10.3854 8.70751 10.6663 8.00027 10.6663C7.29302 10.6663 6.61475 10.3854 6.11465 9.88529C5.61455 9.3852 5.3336 8.70692 5.3336 7.99967H6.66693Z" fill="#2D3748" />
        </svg>

    )
}

export default Trust