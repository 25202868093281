import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { Button, TextField, FormControl, Box, FormHelperText, MenuItem, InputLabel, Select, InputAdornment, IconButton, Typography, Stack, Switch, Checkbox } from '@mui/material';
import { Form, FormikProvider, useFormik } from "formik";
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from "src/hooks/useAxiosPrivate";
import { EVENT_API_URL, USER_API_URL, CONFIG_FIELD } from "src/api/axios";
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import moment from 'moment'
import CloseIcon from "@mui/icons-material/Close";
import AddImageIconPlaceHolder from "src/svgComponents/AddImageIconPlaceHolder";
import _ from "lodash";
import files from 'src/helpers/helpers';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from '@mui/lab';
import EventIcon from "@mui/icons-material/Event";
import { withTranslation } from 'react-i18next';
import MultipleLocationPopUp from "src/components/dashboard/MultipleLocationPopUp";

const UserEventEdit = (props) => {
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { state } = useLocation();
	const eventData = state?.eventData;
	const [imageArray, setImageArray] = useState([]);
	const [videoArray, setVideoArray] = useState([]);
	const [eventMediaFiles, setEventMediaFiles] = useState([]);
	const [eventStartDateTime, setEventStartDateTime] = useState(null);
	const [eventEndDateTime, setEventEndDateTime] = useState(null);
	const [imageFile, setImageFile] = useState([]);
	const [videoFile, setVideoFile] = useState([]);
	const currentDate = new Date();
	const [buttonLoading, setButtonLoding] = useState(false);
	const [startEditDates, setStartEditDate] = useState();
	const [startDates, setStartDate] = useState();
	const [endEditDates, setEndEditDate] = useState();
	const [endDates, setEndDate] = useState();
	const [newEventDateObject, setNewEventDatesObject] = useState([]);
	const [dateRateRangePopup, setDateRangePopup] = useState(false);
	const [selectDuration, setSelectDuration] = useState();
	const [rsvpDate, setRsvpDate] = useState();
	const [eventDurationDays, setEventDurationDays] = useState();
	const [privateCheck, setprivateCheck] = useState(false);
	const [multipleChekcin, setMultipleChekcin] = useState(1);
	const [checkMultipleCheckIn, setCheckMultipleChekcin] = useState(false);
	const [showGuestList, setShowGuestList] = useState(false);
	const [addressEditData, setAddressEditData] = useState([]);

	const nextDate = currentDate.setDate(currentDate.getDate() + 1);

	useEffect(() => {
		if (eventData) {
			setRsvpDate(eventData?.rsvpDate)
			setNewEventDatesObject(eventData?.eventDates)
			setStartEditDate(eventData?.startDateTime)
			setEndEditDate(eventData?.endDateTime)
			setAddressEditData(eventData?.eventAddressData);
			setMultipleChekcin(eventData?.isCheckIn)
			setShowGuestList(eventData?.isGuestShow)
			setprivateCheck(eventData?.visibility === 0 ? false : true);
		}
	}, [eventData])
	const openDateRangePopup = () => {
		setStartEditDate();
		setEndEditDate();
		setDateRangePopup(true)
	}
	const addressData = (data) => {
		if (!_.isEmpty(data)) {
			setFieldValue("address", data);
		} else {
			setFieldValue("address", "");
		}
	};
	useEffect(() => {
		if (eventData?.duration) {
			setEventDuration(eventData?.startDateTime);
		}
	}, [eventData])

	const rsvpDateOnChange = (newValue) => {
		if (isNaN(Date.parse(newValue))) {
			setRsvpDate(eventData?.rsvpDate)
		} else {
			let rsvpDate = new Date(newValue);
			rsvpDate = moment(rsvpDate).format('YYYY-MM-DD')
			setRsvpDate(rsvpDate);
		}


		setEventDuration(startDateRange);
	};

	function setEventDuration(startDateRange) {
		let currentDate = new Date();
		const date1 = new Date(startDateRange);
		const date2 = currentDate;
		const diffInMilliseconds = date1.getTime() - date2.getTime();
		const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
		const days = diffInDays;
		let duration = []
		if (days < 30) {
			duration = [{
				days: 30,
				months: "1 month"
			}]
		} else if (days > 30 && days < 60) {
			duration = [{
				days: 30,
				months: "1 month"
			}, {
				days: 60,
				months: "2 month"
			}]
		} else if (days > 60 && days < 90) {
			duration = [{
				days: 30,
				months: "1 month"
			},
			{
				days: 60,
				months: "2 month"
			},
			{
				days: 90,
				months: "3 month"
			}
			]

		} else if (days > 90 && days < 182) {
			duration = [{
				days: 30,
				months: "1 month"
			},
			{
				days: 60,
				months: "2 month"
			},
			{
				days: 90,
				months: "3 month"
			},
			{
				days: 180,
				months: "6 month"
			}

			]

		} else if (days > 182 && days <= 365 || days >= 366) {

			duration = [{
				days: 30,
				months: "1 month"
			},
			{
				days: 60,
				months: "2 month"
			},
			{
				days: 90,
				months: "3 month"
			},
			{
				days: 180,
				months: "6 month"
			},
			{
				days: 365,
				months: "12 month"
			}

			]
		}
		setEventDurationDays(duration);
	}

	const handleEventStartTime = (eventTime, eventDate) => {
		const start = new Date(eventTime);
		const modifiedArray = newEventDateObject.map((item, index) => {
			if (eventDate === item.date) {
				return {
					date: item?.date,
					start: moment(start).format("HH:mm:ss"),
					end: moment(start).add(1, "hour").format("HH:mm:ss"),
				};
			} else {
				return item;
			}
		});
		setNewEventDatesObject(modifiedArray);
	};
	const handleEventEndTime = (eventTime, eventDate) => {
		const end = new Date(eventTime);
		const modifiedArray = newEventDateObject.map((item, index) => {
			if (eventDate === item.date) {
				return {
					date: item.date,
					start: item.start,
					end: moment(end).format("HH:mm:ss"),
				};
			} else {
				return item;
			}
		});
		setNewEventDatesObject(modifiedArray);
	};

	useEffect(() => {
		if (state) {
			setImageArray(state.eventData.image)
			setVideoArray(state.eventData.video)
			setEventStartDateTime(state.eventData.eventDate)
			const evDateFormat = moment(state.eventData.eventDate).format('YYYY-MM-DD')
			const evDateTime = moment(evDateFormat + ' ' + state.eventData?.time)
			setEventEndDateTime(evDateTime);
		}
	}, [state])

	// useEffect(() => {
	// 	if (!_.isEmpty(state?.eventData?.eventDate && eventStartDateTime && eventEndDateTime)) {
	// 		setFieldValue("startDate", moment(eventStartDateTime).format("YYYY-MM-DD"));
	// 		setFieldValue("endDate", moment(eventEndDateTime).format("HH:mm:ss"));
	// 	}
	// }, [eventStartDateTime, eventEndDateTime]);

	const { data: configurableFieldData, refetch: configurableField } = useQuery(
		["configurablefields"],
		async ({ signal }) => {
			return await axiosPrivate
				.get(CONFIG_FIELD.getConfigFields, { signal })
				.then((res) => res.data);
		},
		{ refetchOnWindowFocus: false }
	);

	const { mutateAsync: videoImageUpload } = useMutation(async (formData) => {
		return await axiosPrivate
			.post(EVENT_API_URL.videoImageUpload, formData)
			.then((res) => res.data);
	});

	const handleRemoveFile = (id) => {
		setEventMediaFiles(
			eventMediaFiles.filter((ele) => {
				return ele.id !== id;
			})
		);
	};

	const handleRemoveFileUpdate = (name, type) => {
		if (type === 'image') {
			setImageArray(
				imageArray.filter((ele) => {
					return ele !== name;
				})
			)
		} else {
			setVideoArray(
				videoArray.filter((ele) => {
					return ele !== name;
				})
			)
		}
	};

	const Uid = () => {
		return "_" + Math.random().toString(36).substring(2, 9);
	};

	const handleEventMediaOnChange = (ev) => {
		const fileTypes = [...ev.target.files];
		if ((imageArray.length + videoArray.length + eventMediaFiles.length + fileTypes.length <= configurableFieldData?.personalEvent?.noOfAttachment) ?? 5) {
			fileTypes?.map((files) => {
				if (files?.type.includes("image") || files?.type.includes("video")) {
					let userSelectedfiles = [files];
					userSelectedfiles.map((file) => {
						file.id = Uid();
						return file;
					});
					setEventMediaFiles((prev) => [...prev, ...userSelectedfiles]);
					ev.target.value = "";
				} else {
					enqueueSnackbar("successfully", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
						autoHideDuration: 2000,
					});
				}
			});
		} else {
			enqueueSnackbar("video and images must be at least " + configurableFieldData?.personalEvent?.noOfAttachment + " limit! ", {
				variant: "error",
				anchorOrigin: { vertical: "top", horizontal: "right" },
				autoHideDuration: 2000,
			});
		}
	};

	useEffect(() => {
		if (!_.isEmpty(eventMediaFiles)) {
			let imageArrays = [];
			let videoArrays = [];
			eventMediaFiles.map((file, index) => {
				if (file?.type.includes("image")) {
					imageArrays.push(file);
					setImageFile(imageArrays);
				} else if (file?.type.includes("video")) {
					videoArrays.push(file);
					setVideoFile(videoArrays);
				} else {
					setVideoFile([])
					setImageFile([])
				}
			});
		}
	}, [eventMediaFiles]);
	const startDateRange = startEditDates ? moment(startEditDates).format('YYYY-MM-DD') : startDates;
	const endDateRange = endEditDates ? moment(endEditDates).format('YYYY-MM-DD') : endDates;

	const completedSchema = Yup.object().shape({
		title: Yup.string().trim()
			.max(configurableFieldData?.personalEvent?.limitForTitle ?? 100, "title must be at least " + configurableFieldData?.personalEvent?.limitForTitle + " characters!")
			.required("title is required"),
		description: Yup.string()
			.trim()
			.max(configurableFieldData?.personalEvent?.limitForDiscription ?? 1000, "description must be at least " + configurableFieldData?.personalEvent?.limitForDiscription + " characters!")
			.required("description is required"),
		// startDate: Yup.mixed().required("event date is required"),
		// endDate: Yup.mixed().required("event time is required"),
		guest: Yup.number()
			.max(configurableFieldData?.personalEvent?.noOfGuest, "must be at least maximum " + configurableFieldData?.personalEvent?.noOfGuest + " guest")
			.required("total number of guest is required")
			.min(1, "total number of guest must be greater than 1"),
		duration: Yup.mixed().required("duration is required"),
	});
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			title: eventData?.title ?? "",
			image: eventData?.image
				? eventData?.image
				: eventData?.video
					? eventData?.video
					: "",
			description: eventData?.description ?? "",
			address: "",
			eventDates: newEventDateObject ? newEventDateObject : [],
			duration: eventData ? eventData.duration : "",
			rsvpDate: eventData ? eventData.rsvpDate : "",
			guest: eventData?.noOfGuest ?? "",

		},
		validationSchema: completedSchema,
		onSubmit: async (values, { resetForm, setFieldError, setSubmitting }) => {
			let images = [];
			let videos = [];
			if (!_.isEmpty(imageFile) || !_.isEmpty(videoFile)) {
				const formData = new FormData();
				imageFile.map((image) => {
					formData.append("images", image);
				});
				videoFile.map((video) => {
					formData.append("videos", video);
				});
				try {
					const response = await videoImageUpload(formData);
					images = response?.images;
					videos = response?.video;
				} catch ({ response: { data } }) {
					enqueueSnackbar("please try again", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
						autoHideDuration: 2000,
					});
				}
			}
			imageArray?.map((result, i) => {
				images?.push(result)
			})
			videoArray?.map((result, i) => {
				videos?.push(result)
			})
			const firstObject = newEventDateObject?.[0];
			const lastObject = newEventDateObject[newEventDateObject?.length - 1];
			const startDateTime = firstObject?.date + ` ` + firstObject?.start
			const endDateTime = lastObject?.date + ` ` + lastObject?.end
			// const uniqueArray = Array.from(new Set(selectInviteUser));

			const informationObj = {
				title: values.title,
				description: values.description,
				guest: values.guest,
				image: images,
				video: videos,
				eventDate: values.startDate,
				time: values.endDate,
				duration: values.duration,
				eventDates: newEventDateObject ?? [],
				startDateTime: startDateTime ?? "",
				endDateTime: endDateTime ?? "",
				eventId: eventData._id,
				rsvpDate: rsvpDate,
				isCheckIn: multipleChekcin,
				isGuestShow: showGuestList ? 1 : 0
			};
			setButtonLoding(true)
			await eventUpdate(informationObj);
			setSubmitting(false);
		},
	});
	const { mutateAsync: eventUpdate } = useMutation(
		async (data) => {
			return await axiosPrivate.put(USER_API_URL.eventUpdate, JSON.stringify(data))
		},
		{
			onSuccess: ({ data }) => {
				enqueueSnackbar("event Update successfully", {
					variant: "success",
					anchorOrigin: { vertical: "top", horizontal: "right" },
					autoHideDuration: 2000,
				});
				setButtonLoding(false)
				navigate(-1);
			},
			onError: (error) => {
				setButtonLoding(false)
				const errorData = error.response.data.errors;
				if (error.response?.data?.message) {
					enqueueSnackbar(error.response?.data?.message, {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
						autoHideDuration: 2000,
					});
				}
				Object.keys(errorData).forEach((key) => {
					if (key === "secondaryNumber") {
						setFieldError("secondaryNumber", errorData[key]);
					} else if (key === "alternativeNumber") {
						setFieldError("alternativeNumber", errorData[key]);
					} else if (key === "userTrace") {
						setFieldError("userTrace", errorData[key]);
					} else {
						setFieldError(key, errorData[key]);
					}
				});
			},
		}
	);
	const { errors, touched, setFieldValue, handleSubmit, setFieldError, getFieldProps, values } = formik;
	const handleSelectDuration = (e) => {
		setSelectDuration(e.target.value)

	}
	const privetEventChange = () => {
		if (privateCheck) {
			setprivateCheck(false);
		} else {
			setprivateCheck(true);
		}
	};
	const handleMultipleCheckboxChange = (e) => {
		const isChecked = e.target.checked
		if (isChecked) {
			setCheckMultipleChekcin(isChecked)
			setMultipleChekcin(e.target.value)
		} else {
			setCheckMultipleChekcin(false)
			setMultipleChekcin(1);
		}
	}
	const handleShowGuestList = (e) => {
		const isChecked = e.target.checked
		if (isChecked) {
			setShowGuestList(true)
			// setMultipleChekcin(e.target.value)
		} else {
			setShowGuestList(false)
			// setMultipleChekcin(1);
		}
	}
	return (
		<>
			<Box className="create_event_wrapper">
				<FormikProvider value={formik}>
					<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
						<Box className="container-fluid">
							<Box className="row">
								<Box className="col-md-12 col-lg-10 col-xl-10 col-xxl-8">
									<Box className="row row-cols-1 g-4 mt-1">
										<Box className="col">
											<Box className="col-xl-8">
												<Box className="d-flex align-items-center justify-content-between mb-3">
													<h4 className="app_text_14 app_text_black app_text_transform">
														event image/video
													</h4>
												</Box>
												<Box className="" style={{ width: "100%" }}>
													<Box className="row g-2">
														{imageArray?.map((imageName, i) => {
															return (
																<>
																	<Box className="col-6" key={i}>
																		<Box className="img_video_wrapper">
																			<span onClick={() => handleRemoveFileUpdate(imageName, 'image')} className="remove_icon" >
																				<CloseIcon style={{ fontSize: 16, color: "#000" }} />
																			</span>
																			<img src={files(imageName, "attachments")} alt="" />
																		</Box>
																	</Box>
																</>
															)
														})}
														{videoArray?.map((videoName, i) => {
															return (
																<>
																	<Box className="col-6" key={i}>
																		<Box className="img_video_wrapper">
																			<span onClick={() => handleRemoveFileUpdate(videoName, 'video')} className="remove_icon" >
																				<CloseIcon style={{ fontSize: 16, color: "#000" }} />
																			</span>
																			<video src={files(videoName?.video, "attachments")} autoPlay controls poster={files(videoName?.thumbnail, "thumb")} loop />
																		</Box>
																	</Box>
																</>
															)
														})}
														{eventMediaFiles && eventMediaFiles.map((ele, i) => {
															return ele?.type.includes("image") ? (
																<Box className="col-6" key={i}>
																	<Box className="img_video_wrapper">
																		<span
																			onClick={() => handleRemoveFile(ele.id)}
																			className="remove_icon"
																		>
																			<CloseIcon
																				style={{
																					fontSize: 16,
																					color: "#000",
																				}}
																			/>
																		</span>
																		<img
																			src={URL.createObjectURL(ele)}
																			alt=""
																		/>
																	</Box>
																</Box>
															) : ele?.type.includes("video") ? (
																<Box className="col-6">
																	<Box className="img_video_wrapper">
																		<span
																			onClick={() => handleRemoveFile(ele.id)}
																			className="remove_icon"
																		>
																			<CloseIcon
																				style={{
																					fontSize: 16,
																					color: "#000",
																				}}
																			/>
																		</span>
																		<video
																			src={URL.createObjectURL(ele)}
																			alt=""
																			autoPlay
																			loop
																		/>
																	</Box>
																</Box>
															) : (
																<></>
															)
														})}
														{imageArray?.length + videoArray?.length + eventMediaFiles?.length < configurableFieldData?.personalEvent?.noOfAttachment ?
															<Box className="col-6">
																<Box
																	className={`img_video_wrapper d-flex align-items-center ${eventMediaFiles.length > 0
																		? "justify-content-center"
																		: "justify-content-start"
																		}`}
																>
																	<Button className="" component="label">
																		<input
																			onChange={(e) =>
																				handleEventMediaOnChange(e)
																			}
																			hidden
																			accept="image/*, video/*"
																			type="file"
																			multiple
																			style={{ display: "none" }}
																		/>
																		<AddImageIconPlaceHolder />
																	</Button>
																</Box>
															</Box>
															:
															<></>
														}
													</Box>
												</Box>
												<FormHelperText error>
													{touched.image && errors.image}
												</FormHelperText>
											</Box>
										</Box>
										<Box className="col">
											<Box className="col-xl-8">
												<TextField
													className="app_text_transform"
													id="title"
													label="title"
													varient="outlined"
													{...getFieldProps("title")}
													error={Boolean(touched.title && errors.title)}
													helperText={touched.title && errors.title}
													fullWidth
												/>
											</Box>
										</Box>
										<Box className="col">
											<Box className="col-xl-8">
												<TextField
													className="app_text_transform"
													id="outlined-multiline-static"
													label="description"
													varient="outlined"
													multiline
													rows={4}
													fullWidth
													{...getFieldProps("description")}
													error={Boolean(
														touched.description && errors.description
													)}
													helperText={touched.description && errors.description}
												/>
											</Box>
										</Box>

										<Box className="col">
											<Box className="col-xl-8">
												<Typography sx={{ font: "18px" }}>max no.of location(2)</Typography>
												<MultipleLocationPopUp
													perviousData={addressEditData}
													label="event location"
													addressData={addressData}
													locationLimit={
														configurableFieldData?.personalEvent?.noOfLocation
													}
												/>
												<FormHelperText error>
													{touched.address && errors.address}
												</FormHelperText>
											</Box>
										</Box>
										{/* <Box className="col">
											<Box className="col-xl-8">
												<TextField
													className="app_text_transform"
													inputProps={{ className: "text-capitalize" }}
													id="color"
													disabled
													label="event color picker"
													varient="outlined"
													fullWidth
													{...getFieldProps("color")}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<AppTooltip
																	title="select status"
																	placement={"bottom"}
																>
																	<IconButton
																		onClick={() => setColorPopup(true)}
																		sx={{ padding: "0px" }}
																	>
																		<ColorLensIcon
																			style={{
																				color: "#757575",
																				fontSize: 26,
																			}}
																		/>
																	</IconButton>
																</AppTooltip>
															</InputAdornment>
														),
														startAdornment: (
															<InputAdornment position="start">
																<RectangleIcon
																	style={{
																		color: values.color,
																		fontSize: 40,
																	}}
																/>
															</InputAdornment>
														),
													}}
													error={Boolean(touched.color && errors.color)}
													helperText={touched.color && errors.color}
												/>
											</Box>
										</Box> */}
										{/* select event dates */}
										<Box className="col">
											<Box className="col-xl-8">
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<FormControl sx={{ width: "100%" }}>
														<TextField
															className="app_text_transform"
															variant="outlined"
															fullWidth
															defaultValue={null}
															value={startDateRange + " to " + endDateRange}
															label="event date"
															id="profileEmail"
															autoComplete="off"
															onClick={() => openDateRangePopup()}
															disabled={true}
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton
																			color="primary"
																			sx={{ p: "10px" }}
																			aria-label="directions"
																			disabled
																		>
																			<EventIcon
																				style={{
																					color: "#97A8BE",
																					fontSize: 20,
																				}}
																			/>
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</FormControl>
												</LocalizationProvider>
											</Box>
										</Box>
										{newEventDateObject?.length > 0 && (
											<Box className="mt-4">
												<Typography sx={{ marginBottom: "16px !important" }}>
													select start and end time
												</Typography>
												{newEventDateObject.map((eventDate, index) => (
													<>
														<Box key={index} className="col-xl-8 mb-3">
															<Typography sx={{ marginBottom: "16px !important", fontWeight: 800 }}>{eventDate?.date}</Typography>
															<Box className="make_text_filds_full">
																<LocalizationProvider
																	dateAdapter={AdapterDayjs}
																>
																	<Box className="row gy-3">
																		<Box className="col-md-6">
																			<TimePicker
																				fullWidth
																				label="event start time"
																				value={moment(
																					eventDate?.start,
																					"HH:mm A"
																				)}
																				className='custom_time_picker1'
																				sx={{
																					"&.MuiFormControl-root": {
																						width: "100% !important"
																					}
																				}}
																				onChange={(newValue) => {
																					handleEventStartTime(
																						newValue,
																						eventDate?.date
																					);
																				}}
																				renderInput={(params) => (
																					<TextField {...params} />
																				)}
																			/>
																		</Box>
																		<Box className="col-md-6">
																			<TimePicker
																				fullWidth
																				className="w-100"
																				label="event end date"
																				// minTime={moment(
																				//   eventDate?.end,
																				//   "HH:mm A"
																				// )}
																				value={moment(
																					eventDate?.end,
																					"HH:mm A"
																				)}
																				onChange={(newValue) => {
																					handleEventEndTime(
																						newValue,
																						eventDate?.date
																					);
																				}}
																				renderInput={(params) => (
																					<TextField {...params} />
																				)}
																			/>
																		</Box>
																	</Box>
																</LocalizationProvider>
															</Box>
														</Box>
													</>
												))}
											</Box>
										)}
										<Box className="col">
											<Box className="col-xl-8">
												<TextField
													type="number"
													// InputProps={{ inputProps: { min: 1, max: configurableFieldData?.personalEvent?.noOfGuest } }}
													className="app_text_transform"
													id="guest"
													label="total number of guest"
													varient="outlined"
													{...getFieldProps("guest")}
													error={Boolean(touched.guest && errors.guest)}
													helperText={touched.guest && errors.guest}
													fullWidth
												/>
											</Box>
										</Box>
										<Box className="col">
											<Box className="col-xl-8">
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<FormControl sx={{ width: "100%" }}>
														<DatePicker
															label="rsvp date"
															value={rsvpDate}
															maxDate={endDateRange}
															onChange={(newValue) => {
																rsvpDateOnChange(newValue);
															}}
															// disabled={Boolean(parentObject?.isVerified)}
															renderInput={(params) => (
																<TextField {...params} />
															)}
														/>
														<FormHelperText error>
															{touched.rsvpDate && errors.rsvpDate}
														</FormHelperText>
													</FormControl>
												</LocalizationProvider>
											</Box>
										</Box>
										<Box className="col">
											<Box className="col-xl-8">
												<FormControl sx={{ width: "100%" }}>
													<InputLabel id="user">
														event visiblity
													</InputLabel>
													<Select
														sx={{ height: 53 }}
														labelId="businessAddressSearchBySelect"
														id="demo-simple-select"
														value={selectDuration}
														label={props.t('references_module.search_by')}
														onChange={(e) => handleSelectDuration(e)}
													>
														{eventDurationDays && eventDurationDays?.map((event, i) => (
															<MenuItem value={event?.days}>{event?.months}</MenuItem>
														))
														}

													</Select>
													<FormHelperText error>
														{touched.duration && errors.duration}
													</FormHelperText>
												</FormControl>
											</Box>
										</Box>
										<Box className="col">
											<Box className="col-xl-8">
												<Stack
													direction="row"
													alignItems="center"
													justifyContent="space-between"
												>
													<span className="app_text_18 app_text_transform">
														private event
													</span>
													<Switch
														onChange={privetEventChange}
														checked={privateCheck}
													/>
												</Stack>
											</Box>
										</Box>

										{
											newEventDateObject.length > 0 ?
												<Box className="col">
													<Box className="col-xl-8">
														<Stack
															direction="row"
															alignItems="center"
															justifyContent="space-between"
														>
															<span className="app_text_18 app_text_transform">
																multiple check-in
															</span>
															<Checkbox
																checked={multipleChekcin == 2 ? "checked" : ""}
																value={2}
																onChange={(e) => { handleMultipleCheckboxChange(e) }}
																// disabled={followListCheck ? false : true}
																sx={{ width: "20px", height: "20px", paddingRight: "30px" }}
															/>
														</Stack>
													</Box>
												</Box>
												: <></>
										}

										{/* show guest list  */}
										<Box className="col">
											<Box className="col-xl-8">
												<Stack
													direction="row"
													alignItems="center"
													justifyContent="space-between"
												>
													<span className="app_text_18 app_text_transform">
														show guest list
													</span>
													<Checkbox
														checked={showGuestList == 1 ? "checked" : ""}
														value={2}
														onChange={(e) => { handleShowGuestList(e) }}
														// disabled={followListCheck ? false : true}
														sx={{ width: "20px", height: "20px", paddingRight: "30px" }}
													/>
												</Stack>
											</Box>
										</Box>


										<Box className="col">
											<Box className="col-xl-8">
												<LoadingButton
													type="submit"
													loading={buttonLoading}
													fullWidth
													loadingPosition="end"
													// disabled={buttonDisable}
													variant="contained"
													className="my-3 app_text_transform text-white app_bg_primary app_text_16_semibold app_btn_lg"
												>
													edit event
												</LoadingButton>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Form>
				</FormikProvider>
			</Box>
		</>
	)
}

export default withTranslation()(UserEventEdit)