import React from 'react'

const UserColoredIcon = () => {
    return (
        // <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M7.33341 9.37499V13.3337H8.66675V9.37499C11.2974 9.70299 13.3334 11.947 13.3334 14.667H2.66675C2.66677 13.3679 3.14087 12.1135 4.00008 11.1392C4.85929 10.1648 6.04455 9.53752 7.33341 9.37499V9.37499ZM8.00008 8.66699C5.79008 8.66699 4.00008 6.87699 4.00008 4.66699C4.00008 2.45699 5.79008 0.666992 8.00008 0.666992C10.2101 0.666992 12.0001 2.45699 12.0001 4.66699C12.0001 6.87699 10.2101 8.66699 8.00008 8.66699Z" fill="#6200EE" />
        // </svg>

        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33335 9.37499V13.3337H6.66669V9.37499C9.29735 9.70299 11.3334 11.947 11.3334 14.667H0.666687C0.666709 13.3679 1.14081 12.1135 2.00002 11.1392C2.85923 10.1648 4.04449 9.53752 5.33335 9.37499ZM6.00002 8.66699C3.79002 8.66699 2.00002 6.87699 2.00002 4.66699C2.00002 2.45699 3.79002 0.666992 6.00002 0.666992C8.21002 0.666992 10 2.45699 10 4.66699C10 6.87699 8.21002 8.66699 6.00002 8.66699Z" fill="#2D3748"/>
        </svg>
        

    )
}

export default UserColoredIcon